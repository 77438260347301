import axios from "axios";
import { GetToken } from "../auth";
const URL = `${process.env.VUE_APP_URL}/projects`;

async function getProjects() {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.get(URL, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function getPublicProjects() {
  try {
    const config = {
      headers: {},
    };
    const resp = await axios.get(
      `${process.env.VUE_APP_URL}/public-projects`,
      config
    );
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function getProject(id) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.get(`${URL}/${id}`, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function createProject(project) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.post(`${URL}`, project, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function updateProject(project) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.put(`${URL}`, project, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

async function deleteProject(id) {
  try {
    const config = {
      headers: {
        authorization: GetToken(),
      },
    };
    const resp = await axios.delete(`${URL}/${id}`, config);
    return resp.data;
  } catch (error) {
    return {
      error: true,
      message: error.toString(),
    };
  }
}

const projectAPI = {
  getProjects,
  getProject,
  createProject,
  updateProject,
  deleteProject,
  getPublicProjects,
};

export { projectAPI };

<template>
  <v-row class="pt-15">
    <v-col cols="12" class="px-lg-16">
      <v-row align="center" justify="center" class="px-10">
        <v-col cols="12" md="6" align="center">
          <v-card
            flat
            color="secondary"
            max-width="450"
            class="mt-10 pa-5 text-left"
            data-aos="fade-right"
            data-aos-duration="1500"
            data-aos-delay="2600"
            data-aos-anchor-placement="bottom-center"
          >
            <v-card-text>
              <h1 class="primary--text title-font text-lg-h4 text-md-h4 pb-5">
                {{ $t("lbMision") }}
              </h1>
              <p class="pb-0 mb-0 body-font">
                {{ $t("lbMisionText") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" align="center">
          <v-card
            flat
            color="secondary"
            max-width="450"
            class="mt-10 pa-5 text-left"
            data-aos="fade-left"
            data-aos-duration="1500"
            data-aos-delay="2600"
            data-aos-anchor-placement="bottom-center"
          >
            <v-card-text>
              <h1 class="primary--text title-font text-lg-h4 text-md-h4 pb-5">
                {{ $t("lbVision") }}
              </h1>
              <p class="pb-0 mb-0 body-font">
                {{ $t("lbVisionTxt") }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MisionVision",
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped></style>

<template>
  <div data-aos="fade" data-aos-delay="500" data-aos-duration="1500">
    <v-img :src="item.url" max-width="100" contain></v-img>
    <div
      class="pt-3"
      :style="
        $vuetify.breakpoint.mdAndUp
          ? ` width: ${$vuetify.breakpoint.md ? item.width.md : item.width.lg}`
          : ''
      "
    >
      <small class="primary--text title-font text-h6">
        {{ item.name }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividualValue",
  props: {
    item: Object,
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped></style>

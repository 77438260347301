import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#115989",
        secondary: "#EBE0D6",
        secondary_original: "#976434",
        custom_blue: "#001a57",
        custom_grey: "#E7EEF3",
        support: colors.grey.darken2,
      },
    },
  },
});

<template>
  <v-row id="heading-opinion" class="" no-gutters>
    <v-col
      cols="12"
      sm="7"
      class="px-10 px-md-16 secondary pt-md-10"
      order="2"
      order-md="1"
    >
      <v-card flat color="transparent" class="pa-5">
        <h1
          class="pt-5 pt-md-15 primary--text title-font text-h4 text-lg-h3 text-md-h3"
          data-aos="fade-down"
          data-aos-delay="1800"
          data-aos-duration="1500"
          data-aos-anchor-placement="center-center"
        >
          {{ $t("titleOpiniones") }}
        </h1>
        <v-row no-gutters class="px-md-4 px-lg-10 pt-md-16 pt-10">
          <v-col cols="12" md="8" class="">
            <v-btn
              @click="gotoOpiniones"
              x-large
              block
              color="primary"
              elevation="0"
              class="mt-0 ml-md-4"
            >
              {{ $t("btnSeeReviews") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" sm="5" class="blue cover-status" order="1" order-md="2">
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "OpinionBanner",
  components: {},
  data: function () {
    return {};
  },
  methods: {
    gotoOpiniones() {
      this.$router.push({ name: "Opiniones" });
    },
  },
};
</script>

<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.cover-status {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("~@/assets/images/manos.png") !important;
}
</style>

<template>
  <v-row id="heading-4" class="" no-gutters>
    <v-col cols="12" sm="5" class="blue cover-status"> </v-col>
    <v-col cols="12" sm="7" class="px-10 px-md-16 secondary">
      <v-card flat color="transparent" class="pa-5">
        <h1
          class="pt-15 primary--text title-font text-h4 text-lg-h3 text-md-h3"
          data-aos="fade-down"
          data-aos-delay="1800"
          data-aos-duration="1500"
          data-aos-anchor-placement="center-center"
        >
          {{ $t("titleEstadoProyecto") }}
        </h1>
        <h5
          class="support--text body-font pt-8 pb-16 text-h6 pr-lg-8"
          data-aos="fade-up"
          data-aos-delay="2500"
          data-aos-duration="1500"
          data-aos-anchor-placement="center-center"
        >
          {{ $t("subtitleEstadoProyecto") }}
        </h5>
        <v-row no-gutters class="px-md-4 px-lg-10">
          <v-col cols="12" md="8">
            <v-text-field
              v-model="no_guia"
              filled
              color="primary"
              :placeholder="$t('lblNumeroGuia')"
              persistent-hint
              :hint="$t('lblHelperGuia')"
              dense
            >
              <template v-slot:append-outer>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" @click="handleGuide(true)">
                      mdi-help-circle
                    </v-icon>
                  </template>
                  {{ $t("lbSaberMas") }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" class="">
            <v-btn
              id="gtaller_modal"
              data-gt-ventana="1"
              large
              :block="$vuetify.breakpoint.xs"
              color="primary"
              elevation="0"
              class="mt-0 ml-md-4"
              :disabled="!no_guia"
              @click="verificar"
            >
              {{ $t("btnVerificar") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <my-default-dialog
        v-if="show_guide"
        :dialog-default="show_guide"
        @close="handleGuide(false)"
      />
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
import { messages } from "@/utils";
import MyDefaultDialog from "@/components/ui/MyDefaultDialog.vue";

export default {
  name: "projectStatus",
  components: { MyDefaultDialog },
  data: function () {
    return {
      no_guia: "",
      show_guide: false,
    };
  },
  methods: {
    handleGuide(val) {
      this.show_guide = val;
    },
    async verificar() {
      try {
        const response = await axios.get(
          `https://taller.gestioo.net/taller/api/validar_orden/${this.no_guia}`
        );
        if (response.data.error === 0) {
          // La orden existe
          /*window.open(
            `https://taller.gestioo.net/taller/consulta/orden/${this.no_guia}`
          );*/
          window.location.href = `https://taller.gestioo.net/taller/consulta/orden/${this.no_guia}`;
        } else {
          // La orden no existe
          this.no_guia = "";
          messages.warning("Orden incorrecta");
        }
      } catch (error) {
        console.error("Error al verificar la orden:", error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.cover-status {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("~@/assets/images/brujula.png") !important;
}
</style>

<template>
  <v-dialog v-model="dialog" max-width="550px" persistent>
    <v-card class="">
      <v-card-text class="pt-3 px-10">
        <v-row class="pt-5">
          <v-col cols="12" class="pt-4" align="center">
            <h2 class="secondary_original--text">
              {{ $t("titleDialogGuia") }}
            </h2>
            <p class="text-left pt-5 font-weight-medium">
              {{ $t("lbDialogGuia") }}
            </p>
          </v-col>
          <v-col cols="12" align="center">
            <v-img contain :src="'/img/cotizacion.svg'"> </v-img>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions align="center" class="pt-0 px-8">
        <v-spacer> </v-spacer>
        <v-btn @click="close" color="support" outlined>
          {{ $t("lbAceptar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MyDefaultDialog",
  props: {
    dialogDefault: Boolean,
  },
  data: function () {
    return {
      dialog: this.dialogDefault,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

import axios from "axios";
import i18n from "@/i18n";

/*{
    "name": "String",
    "email": "String",
    "reason": "String",
    "comment": "String",
    "token": "String"
}*/
export async function contactanos(data) {
  try {
    const url = `${process.env.VUE_APP_URL}/contactanos`;
    const config = {
      method: "post",
      url: url,
      headers: {},
      data,
    };
    const response = await axios(url, config);
    return {
      ...response.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.message || i18n.t("errorInterno"),
      error: true,
    };
  }
}

<template>
  <v-col
    cols="12"
    md="10"
    lg="8"
    class="px-7 px-md-0 offset-lg-2 offset-md-1"
    data-aos="fade"
    data-aos-delay="500"
    data-aos-duration="1500"
  >
    <v-row>
      <v-col cols="12" md="6" v-for="(item, index) in items" :key="index">
        <v-lazy
          :options="{
            threshold: 0.25,
          }"
          transition="fade-transition"
        >
          <div>
            <v-img
              :height="height"
              :src="item.image"
              @click="goToDetail(item.id)"
            >
              <template v-slot:placeholder>
                <v-sheet height="350px">
                  <v-skeleton-loader
                    class="mx-auto img-loader"
                    loading
                    type="image"
                    height="350px"
                  ></v-skeleton-loader>
                </v-sheet>
              </template>
            </v-img>
            <v-card flat color="white" class="pb-2 text-left">
              <v-card-text class="px-md-0 mx-md-0">
                <v-row no-gutters>
                  <v-col cols="12">
                    <h2
                      class="pb-0 mb-0 body-font primary--text text-md-h5 font-weight-medium"
                      style="min-height: 52px"
                    >
                      {{ language === "es" ? item.name : item.nameEng }}
                    </h2>
                    <h3
                      class="short-description pt-5 pb-0 mb-0 body-font font-weight-regular"
                    >
                      {{
                        language === "es"
                          ? item.shortDescription
                          : item.shortDescriptionEng
                      }}
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    align="right"
                    class="d-flex justify-end align-center pt-4"
                    data-aos="fade-right"
                    data-aos-delay="300"
                    data-aos-duration="1500"
                    data-aos-anchor-placement="bottom-bottom"
                    @click="goToDetail(item.id)"
                  >
                    <h4 class="font-weight-medium body-font pr-md-3">
                      {{ $t("lblContinuarLeyendo") }}
                    </h4>
                    <div class="pl-3">
                      <v-btn
                        icon
                        outlined
                        small
                        color="primary"
                        @click="goToDetail(item.id)"
                      >
                        <v-icon small>mdi-arrow-right</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-lazy>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: "DefaultView",
  props: {
    items: Array,
  },
  data: function () {
    return {
      height: 350,
      language: localStorage.getItem("language"),
    };
  },
  methods: {
    goToDetail(id) {
      if (id) {
        this.$router
          .push({
            name: "DetailedView",
            params: { id },
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              console.error(err);
            }
          });
      } else {
        console.error("ID is missing");
      }
    },
    dimensions() {
      if (this.$vuetify.breakpoint.md) {
        this.height = 250;
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        this.height = 300;
      }
    },
  },
  mounted() {
    this.dimensions();
  },
};
</script>

<style scoped>
::v-deep .v-skeleton-loader > * {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep .v-skeleton-loader .v-skeleton-loader__bone {
  flex-grow: 1;
}
</style>
<style scoped src="@/assets/css/home.css"></style>
<style scoped src="@/assets/css/proyects.css"></style>

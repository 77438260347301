<template>
  <v-app-bar
    app
    elevate-on-scroll
    :color="bg"
    class="py-2"
    min-height="85"
    id="app_bar"
  >
    <v-app-bar-nav-icon
      class="hidden-sm-and-up"
      :class="colorHamburger"
      @click="handleSideBar()"
    />
    <v-spacer v-if="$vuetify.breakpoint.xsOnly"></v-spacer>
    <v-img
      contain
      :max-width="widthLogo"
      :src="logo"
      position="left"
      class=""
      @click="handleNavigation(itemIcon)"
    >
    </v-img>
    <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
    <div class="mr-5 mr-md-5 d-inline-flex hidden-xs-only">
      <v-btn
        v-for="item in items"
        :key="item.title"
        @click="handleNavigation(item)"
        :text="item.text"
        :outlined="item.outlined"
        :color="menuColor"
        class="hidden-xs-only"
      >
        <small class="custom-font">{{ item.title }} </small>
      </v-btn>
    </div>
    <v-menu bottom rounded offset-y nudge-width="50">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-chip
              label
              outlined
              class="ma-2 py-4"
              :color="menuColor"
              :text-color="menuColor"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
            >
              <country-flag :country="flag" size="small" />
              <v-icon right small> mdi-chevron-down </v-icon>
            </v-chip>
          </template>
          <span>{{ $t("lblIdioma") }}</span>
        </v-tooltip>
      </template>
      <v-list class="">
        <v-list-item-group
          v-model="selectedLanguage"
          @change="storeLanguage"
          color="blue"
        >
          <v-list-item v-for="item in lenguajes" :key="item.id">
            <v-list-item-title>
              <country-flag :country="item.flag" size="small" />
              <span class="ml-3">
                {{ item.name }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { Trans } from "@/plugins/translate";

export default {
  name: "CustomAppBar",
  props: {
    sidebar: Boolean,
    items: Array,
    itemIcon: Object,
    isLight: Boolean,
  },
  data: function () {
    return {
      selectedLanguage: localStorage.getItem("language") === "en" ? 1 : 0,
      lenguajes: [
        { id: "es", name: this.$t("nameEspanol"), flag: "gt" },
        { id: "en", name: this.$t("nameIngles"), flag: "us" },
      ],
      widthLogo: 255,
      app_bar_color: "transparent",
      internalSidebar: this.sidebar,
      menuColor: "custom_blue",
      bg: "transparent",
      logo: "/img/lg_3.png",
      flag: localStorage.getItem("language") === "en" ? "us" : "gt",
      colorHamburger: "grey--text text--darken-2",
    };
  },
  watch: {
    sidebar(val) {
      this.internalSidebar = val;
    },
  },
  methods: {
    storeLanguage(index) {
      const { id } = this.lenguajes[index];
      Trans.currentLocale(id);
      localStorage.setItem("language", id);
      location.reload();
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
      }
      if (this.$vuetify.breakpoint.xs) {
        this.handleSideBar();
      }
    },
    handleSideBar() {
      this.$emit("sidebar-event", !this.internalSidebar);
    },
    changeColor() {
      if (this.isLight == true) return;
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        this.bg = "white";
        this.menuColor = "grey darken-2";
        this.logo = "/img/lg_3.png";
        this.colorHamburger = "grey--text text--darken-2";
      } else {
        this.bg = "transparent";
        this.menuColor = "custom_blue";
        this.logo = "/img/lg_3.png";
        this.colorHamburger = "grey--text text--darken-2";
      }
    },
    setValuesLight() {
      this.bg = "white";
      this.menuColor = "grey darken-2";
      this.logo = "/img/lg_3.png";
      this.colorHamburger = "grey--text text--darken-2";
    },
  },
  mounted() {
    if (!this.isLight) {
      window.onscroll = () => {
        this.changeColor();
      };
    } else {
      this.setValuesLight();
    }
  },
};
</script>

<style scoped></style>

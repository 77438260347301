<template>
  <v-footer padless color="primary">
    <v-card class="flex" flat tile color="primary">
      <v-card-text class="py-2 white--text text-center">
        &copy; {{ new Date().getFullYear() }} —
        <strong>G&M Servicios Nauticos</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "CustomFooter",
};
</script>

<style scoped></style>

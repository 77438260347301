<template>
  <v-row id="" class="" no-gutters>
    <v-col cols="12" sm="5" class="blue cover-status"> </v-col>
    <v-col cols="12" sm="7" class="px-10 px-md-16 secondary">
      <v-card flat color="transparent" class="pa-5">
        <h1
          class="pt-15 primary--text title-font text-h4 text-lg-h3 text-md-h3"
          data-aos="fade-down"
          data-aos-delay="1800"
          data-aos-duration="1500"
          data-aos-anchor-placement="center-center"
        >
          {{ $t("titleInvitarSeguirnos") }}
        </h1>
        <h5
          class="support--text body-font pt-8 pb-16 text-h6 pr-lg-8"
          data-aos="fade-up"
          data-aos-delay="2500"
          data-aos-duration="1500"
        >
          {{ $t("subtitleInvitar") }}
        </h5>
        <v-row
          align="center"
          justify="center"
          class="mx-md-10 px-md-10 pb-10 pb-md-1"
        >
          <v-col cols="3" md="4">
            <v-img
              :src="urlFacebook"
              width="80"
              class="cursor"
              @click="goToFb"
            ></v-img>
          </v-col>
          <v-col cols="3" md="4">
            <v-img
              @click="goToIG"
              :src="urlInstagram"
              width="80"
              class="cursor"
            ></v-img>
          </v-col>
          <v-col cols="3" md="4">
            <v-img @click="goToTKT" :src="urlTiktok" width="80" class="cursor">
            </v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "OpinionBannerSocialMedia",
  components: {},
  data: function () {
    return {
      urlFacebook: "/img/iconos/i-facebook.png",
      urlTiktok: "/img/iconos/i-tiktok.png",
      urlInstagram: "/img/iconos/i-instagram.png",
    };
  },
  methods: {
    goToFb() {
      window.open("https://www.facebook.com/gymservinauticos", "blank_");
    },
    goToTKT() {
      window.open("https://www.tiktok.com/@gymserviciosnauticos", "blank_");
    },
    goToIG() {
      window.open("https://www.instagram.com/gymserviciosnauticos", "blank_");
    },
  },
};
</script>

<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.cover-status {
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-image: url("~@/assets/images/person.png") !important;
}

.cursor {
  cursor: pointer;
}
</style>

<template>
  <v-row no-gutters>
    <v-col cols="12" align="center" class="pt-15 px-5 px-md-1">
      <h1
        class="py-15 primary--text title-font text-h5 text-lg-h4 text-md-h4"
        data-aos="fade-down"
        data-aos-delay="300"
        data-aos-duration="1500"
      >
        {{ $t("lblNuestrosValores") }}
      </h1>
    </v-col>
    <v-col cols="12" md="10" lg="8" class="offset-md-1 offset-lg-2 pb-10">
      <v-row>
        <v-col
          cols="12"
          :md="value.fullColum ? 12 : 4"
          align="center"
          class="py-10 px-15 px-md-0"
          v-for="value in values"
          :key="value.name"
        >
          <individual-value :item="value" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import IndividualValue from "@/components/Home/IndividualValue.vue";

export default {
  name: "OurValues",
  components: { IndividualValue },
  data: function () {
    return {
      values: [
        {
          name: this.$t("nameCompromiso"),
          url: "/img/iconos/vCompromiso.png",
          width: {
            md: "50%",
            lg: "50%",
          },
          fullColum: false,
        },
        {
          name: this.$t("nameServicioCliente"),
          url: "/img/iconos/vServicio.png",
          width: {
            md: "60%",
            lg: "50%",
          },
          fullColum: false,
        },
        {
          name: this.$t("nameCrecimientoProfesional"),
          url: "/img/iconos/vCrecimiento.png",
          width: {
            md: "80%",
            lg: "60%",
          },
          fullColum: false,
        },
        {
          name: this.$t("nameInnovacionContinua"),
          url: "/img/iconos/vInnovacion.png",
          width: {
            md: "80%",
            lg: "60%",
          },
          fullColum: false,
        },
        {
          name: this.$t("nameColaboracion"),
          url: "/img/iconos/vColaboracion.png",
          width: {
            md: "90%",
            lg: "70%",
          },
          fullColum: false,
        },
        {
          name: this.$t("nameIntegridad"),
          url: "/img/iconos/vIntegridad.png",
          width: {
            md: "90%",
            lg: "80%",
          },
          fullColum: false,
        },
        {
          name: this.$t("nameCalidad"),
          url: "/img/iconos/vCalidad.png",
          width: {
            md: "90%",
            lg: "80%",
          },
          fullColum: true,
        },
      ],
    };
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped></style>

<template>
  <v-app>
    <custom-app-bar
      :sidebar="sidebar"
      :items="items"
      :item-icon="itemIcon"
      @sidebar-event="handleSideBar"
      is-light
    />
    <v-navigation-drawer
      v-model="sidebar"
      app
      dark
      color="primary"
      style="width: 100%"
      src="/img/bg-a.jpg"
    >
      <v-layout column justify-center justify-space-evenly fill-height>
        <div class="">
          <v-list-item>
            <v-list-item-title class="d-flex justify-center align-center">
              <v-img
                src="/img/logo-vertical-white.png"
                alt="alt"
                class=""
                max-width="150"
              />
            </v-list-item-title>
          </v-list-item>
        </div>
        <v-list dense nav expand class="mt-10 pt-10 mx-16">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            @click="handleNavigation(item)"
          >
            <v-list-item-avatar>
              <v-icon color="white"> mdi-waves </v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="py-3">
              <h3>{{ item.title }}</h3>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
      <template v-slot:append>
        <div class="pb-5 d-flex align-center justify-center">
          <v-btn
            @click="handleSideBar(false)"
            icon
            outlined
            elevation="0"
            color="secondary"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main class="">
      <v-row id="" class="pb-10">
        <v-col cols="12" align="center" class="pt-15 px-5 px-md-0">
          <h1
            class="pt-15 primary--text title-font text-lg-h3 text-md-h3"
            data-aos="fade-down"
            data-aos-delay="200"
            data-aos-duration="1500"
          >
            {{ $t("lbOpinionesCliente") }}
          </h1>
          <h4
            class="font-weight-medium support--text body-font pt-8 pb-16 text-lg-h6 px-md-16"
            data-aos="fade-down"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            {{ $t("lbOpinionesText") }}
          </h4>
        </v-col>
      </v-row>
      <v-row class="px-md-16 mx-md-16 d-flex align-center justify-center">
        <v-col cols="12" class="max-w">
          <v-row>
            <v-col cols="12" md="6">
              <v-img :src="urlS1"></v-img>
              <v-img :src="urlS2"></v-img>
              <v-img :src="urlmd1"></v-img>
            </v-col>
            <v-col cols="12" md="6" class="mt-md-16">
              <v-img :src="urlmd2"></v-img>
              <v-img :src="urlmd3"></v-img>
              <v-img :src="urlS3"></v-img>
              <v-img :src="urlmd4"></v-img>
              <v-img :src="urlmd5"></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="py-16">
        <v-col cols="12" class="py-10">
          <opinion-banner-social-media />
        </v-col>
      </v-row>
      <div v-if="showButtonTop" class="floating_container">
        <v-btn
          color="custom_blue"
          elevation="5"
          rounded
          dark
          @click="goToTop()"
        >
          <v-icon> mdi-arrow-up </v-icon>
        </v-btn>
      </div>
    </v-main>
    <custom-footer />
  </v-app>
</template>

<script>
import CustomAppBar from "@/components/Home/CustomAppBar";
import CustomFooter from "@/components/Home/CustomFooter";
import { mapState } from "vuex";
import OpinionBannerSocialMedia from "@/components/opiniones/OpinionBannerSocialMedia.vue";
export default {
  name: "OpinionesView",
  components: { OpinionBannerSocialMedia, CustomFooter, CustomAppBar },
  data: function () {
    return {
      isMobile: false,
      sidebar: false,
      items: [
        {
          title: this.$t("titleRegresarInicio"),
          nameRoute: "home",
          text: false,
          outlined: true,
          toPage: true,
        },
      ],
      itemIcon: {
        nameRoute: "home",
        outlined: true,
        toPage: true,
      },
      showButtonTop: false,
      urlS1: "/img/opiniones/s1.png",
      urlS2: "/img/opiniones/s2.png",
      urlS3: "/img/opiniones/s3.png",
      urlmd1: "/img/opiniones/md1.png",
      urlmd2: "/img/opiniones/md2.png",
      urlmd3: "/img/opiniones/md3.png",
      urlmd4: "/img/opiniones/md4.png",
      urlmd5: "/img/opiniones/md5.png",
    };
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    goToTop() {
      this.$vuetify.goTo("#app_bar");
    },
    handleSideBar(sidebarValue) {
      this.sidebar = sidebarValue;
    },
    handleNavigation(item) {
      if (item.toPage) {
        this.$router.push({ name: item.nameRoute });
      } else {
        this.$vuetify.goTo(item.tarjet);
      }
      if (this.$vuetify.breakpoint.xs) {
        this.handleSideBar();
      }
    },
    onResize() {
      if (window.innerWidth >= 600) {
        this.sidebar = false;
      }
    },
    handleTopButton() {
      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        this.showButtonTop = true;
      } else {
        this.showButtonTop = false;
      }
    },
  },
  mounted() {
    window.onscroll = () => {
      this.handleTopButton();
    };
    this.onResize();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped src="@/assets/css/home.css"></style>
<style scoped>
.max-w {
  max-width: 1200px;
}

.floating_container {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 30px;
  z-index: 100;
}
</style>

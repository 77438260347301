import { projectAPI } from "@/api";
import { messages } from "@/utils";

const state = () => ({
  projects: [],
  publicProjects: [],
  project: null,
  loadingSave: false,
  loading: false,
});

// getters
const getters = {};

// actions
const actions = {
  async getProject({ commit }, { id }) {
    commit("setLoading", true);
    const project = await projectAPI.getProject(id);
    if (project.error) {
      commit("setLoading", false);
      messages.error(project.message);
      return;
    }
    commit("setProject", project);
  },
  async getProjects({ commit }) {
    commit("setLoading", true);
    const projects = await projectAPI.getProjects();
    if (projects.error) {
      commit("setLoading", false);
      messages.error(projects.message);
      return;
    }
    commit("setProjects", projects);
  },
  async createProject({ commit }, { data }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.createProject(data);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("El proyecto ha sido creado");
    commit("createProject", { ...data, id: project.id });
  },
  async updateProject({ commit }, { data }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.updateProject(data);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("El proyecto ha sido actualizado");
    commit("updateProject", data);
  },
  async deleteProject({ commit }, { id }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.deleteProject(id);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("Se ha eliminado el proyecto");
    commit("deleteProject", id);
  },
  async getPublicProjects({ commit }) {
    commit("setLoading", true);
    const projects = await projectAPI.getPublicProjects();
    if (projects.error) {
      commit("setLoading", false);
      messages.error(projects.message);
      return;
    }
    commit(
      "setPublicProjects",
      projects.slice().filter((item) => item.published === true)
    );
  },
  async setImages({ commit }, { data }) {
    commit("setLoadingSave", true);
    const project = await projectAPI.updateProject(data);
    if (project.error) {
      commit("setLoadingSave", false);
      messages.error(project.message);
      return;
    }
    messages.success("Las imágenes han sido agregadas");
    commit("updateProject", data);
  },
};

// mutations
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setLoadingSave(state, loading) {
    state.loadingSave = loading;
  },
  setProjects(state, projects) {
    state.loading = false;
    state.projects = projects;
  },
  setPublicProjects(state, projects) {
    state.loading = false;
    state.publicProjects = projects;
  },
  setProject(state, project) {
    state.loading = false;
    state.project = project;
  },
  createProject(state, project) {
    state.loadingSave = false;
    state.projects = [
      {
        ...project,
      },
      ...state.projects,
    ];
  },
  updateProject(state, project) {
    const index = state.projects.findIndex((item) => item.id === project.id);
    const items = [...state.projects];
    items[index] = {
      ...items[index],
      ...project,
    };
    state.loadingSave = false;
    state.projects = items;
  },
  deleteProject(state, id) {
    const index = state.projects.findIndex((item) => item.id === id);
    const items = [...state.projects];
    items.splice(index, 1);
    state.loadingSave = false;
    state.projects = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
